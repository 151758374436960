<template>
  <panel
    :breadcrumbs="breadcrumbs"
    :title="title"
  >
    <v-card>
      <v-toolbar
        card
      >
        <v-toolbar-items>
          <v-subheader>Search</v-subheader>
          <v-text-field
            v-model="searchAccount"
            label="Enter value"
            placeholder="MSISDN or Account #"
            clearable
          />
          <v-btn
            flat
            @click="searchByMsisdn"
          >
            by MSISDN
          </v-btn>
          <v-btn
            flat
            @click="searchByAccountNumber"
          >
            by Account Number
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
    </v-card>
    <v-card>
      <v-card-title>Family Plan</v-card-title>
      <v-card-text>
        <v-container grid-list-xs>
          <v-layout
            row
            wrap
          >
            <template v-for="field in self">
              <v-flex
                :key="field.name"
                xs4
              >
                <span class="primary--text">{{ field.name }}</span>
              </v-flex>
              <v-flex
                :key="field.name + 'value'"
                xs8
              >
                {{ field.value }}
              </v-flex>
            </template>
          </v-layout>
        </v-container>
      </v-card-text>
      <v-divider />
    </v-card>
    <v-card>
      <v-card-title>Members</v-card-title>
      <v-card-text>
        <v-data-table
          :headers="Memberheaders"
          :items="members"
          :loading="loading"
          :expand="true"
          item-key="entry"
          class="px-1 pb-1"
        >
          <template #items="props">
            <tr @click="props.expanded = !props.expanded">
              <td
                v-if="props.item.entry"
                class="text-xs-left"
              >
                {{ props.item.entry }}
              </td>
              <td
                v-else
                class="text-xs-left"
              >
                -
              </td>
              <td
                v-if="props.item.msisdn"
                class="text-xs-left"
              >
                {{ props.item.msisdn }}
              </td>
              <td
                v-else
                class="text-xs-left"
              >
                -
              </td>
              <td
                v-if="props.item.name"
                class="text-xs-left"
              >
                {{ props.item.name }}
              </td>
              <td
                v-else
                class="text-xs-left"
              >
                -
              </td>
              <td
                v-if="props.item.status"
                class="text-xs-left"
              >
                {{ props.item.status.charAt(0).toUpperCase() + props.item.status.slice(1) }}
              </td>
              <td
                v-else
                class="text-xs-left"
              >
                -
              </td>
              <td
                v-if="props.item.role"
                class="text-xs-left"
              >
                {{ props.item.role }}
              </td>
              <td
                v-else
                class="text-xs-left"
              >
                -
              </td>
            </tr>
          </template>
          <template slot="no-data">
            <v-alert
              :value="true"
              :type="error ? 'error' : 'info'"
            >
              {{ noDataText }}
            </v-alert>
          </template>
          <template slot="no-result">
            <v-alert
              :value="true"
              type="info"
            >
              {{ noResultText }}
            </v-alert>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-divider />
    <v-card>
      <v-card-title>Activities</v-card-title>
      <v-card>
        <v-toolbar
          dense
          card
        >
          <v-menu
            v-model="menu"
            close-on-content-click
            transition="scale-transition"
            full-width
            min-width="290px"
          >
            <v-text-field
              slot="activator"
              v-model="monthOf"
              prepend-icon="event"
              readonly
            />
            <v-date-picker
              v-model="monthOf"
              :max="maxDate"
              :allowed-dates="allowedMonths"
              min="2018-01"
              type="month"
              year-icon="date_range"
            />
          </v-menu>
          <v-spacer />
        </v-toolbar>
        <v-card-title>
          <v-spacer />
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Search"
            single-line
            solo-inverted
            clearable
            box
            hide-details
          />
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="data"
          :search="search"
          :rows-per-page-items="[10, 25, 50, 100]"
          :expand="true"
          item-key="entry"
          class="px-1 pb-1"
        >
          <template #items="props">
            <tr @click="props.expanded = !props.expanded">
              <td class="text-xs-left">
                {{ props.item.entry }}
              </td>
              <td class="text-xs-left">
                {{ props.item.action }}
              </td>
              <td class="text-xs-left">
                {{ props.item.target }}
              </td>
              <td class="text-xs-left">
                {{ props.item.phase }}
              </td>
              <td
                v-if="props.item.status === 'Success'"
                class="text-xs-left success--text"
              >
                {{ props.item.status }}
              </td>
              <td
                v-else
                class="text-xs-left error--text"
              >
                {{ props.item.status }}
              </td>
              <td class="text-xs-left">
                {{ props.item.remark }}
              </td>
            </tr>
          </template>
          <template slot="no-data">
            <v-alert
              :value="true"
              :type="error ? 'error' : 'info'"
            >
              {{ noDataText }}
            </v-alert>
          </template>
        </v-data-table>
      </v-card>
    </v-card>
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card>
        <v-card-title
          class="headline red lighten-2"
          primary-title
        >
          Error
        </v-card-title>

        <v-card-text>
          {{ responseError }}
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            flat
            @click="dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </panel>
</template>

<script>
import { DateTime, Interval } from 'luxon'
import { createGetParams } from '@/rest'

const pageTitle = 'FamilyPlan'

export default {
  props: {
    id: {
      required: false,
      type: String,
      default: null,
    },
  },
  components: {
    Panel: () => import(/* webpackChunkName: "widePanel" */ '@/components/WidePanel'),
  },
  data () {
    return {
      breadcrumbs: [
        {
          text: pageTitle, disabled: true,
        },
      ],
      error: null,
      responseError: null,
      dialog: false,
      loading: false,
      maxDate: '',
      menu: false,
      monthOf: null,
      noDataText: "There's nothing to display.",
      noResultText: 'No data that fits that criteria.',
      search: '',
      searchAccount: null,
      self: null,
      data: [],
      members: [],
      title: pageTitle,
      headers: [
        {
          align: 'left',
          sortable: true,
          text: 'Entry',
          value: 'entry',
        },
        {
          align: 'left',
          sortable: true,
          text: 'Action',
          value: 'action',
        },
        {
          align: 'left',
          sortable: true,
          text: 'MSISDN',
          value: 'target',
        },
        {
          align: 'left',
          sortable: true,
          text: 'Detail',
          value: 'phase',
        },
        {
          align: 'left',
          sortable: true,
          text: 'Status',
          value: 'status',
        },
        {
          align: 'left',
          sortable: false,
          text: 'Remark',
          value: 'remark',
        },
      ],
      Memberheaders: [
        {
          align: 'left',
          sortable: true,
          text: 'Entry',
          value: 'entry',
        },
        {
          align: 'left',
          sortable: true,
          text: 'MSISDN',
          value: 'msisdn',
        },
        {
          align: 'left',
          sortable: true,
          text: 'Name',
          value: 'name',
        },
        {
          align: 'left',
          sortable: true,
          text: 'Status',
          value: 'status',
        },
        {
          align: 'left',
          sortable: true,
          text: 'Role',
          value: 'role',
        },
      ],
    }
  },
  created: function () {
    this.getDataResponse()
  },
  watch: {
    monthOf: function (val) {
      this.getActivities(val)
    },
  },
  mounted: function () {
    this.searchAccount = this.id
    this.getActivities()
    const now = DateTime.local().setZone('Asia/Kuala_Lumpur')
    this.maxDate = this.monthOf = now.toFormat('yyyy-LL')
  },
  methods: {
    allowedMonths: function (month) {
      const ym = month.split('-')
      const whatMonth = DateTime.local(Number(ym[0]), Number(ym[1])).setZone('Asia/Kuala_Lumpur')
      const fromDate = DateTime.local(2018, 1, 1).setZone('Asia/Kuala_Lumpur')
      const now = DateTime.local().setZone('Asia/Kuala_Lumpur')

      return Interval.fromDateTimes(fromDate, now).contains(whatMonth)
    },
    getDataResponse () {
      const params = createGetParams({
        msisdn: this.id,
      })
      this.loading = true
      this.$rest.get('getFamilyAccount.php', params)
        .then(function (response) {
          this.self = response.data.self
          this.members = response.data.members
          this.loading = false
        }.bind(this)).catch(error => {
          this.noDataText = error.message
          this.loading = false
        })
    },
    getActivities (month) {
      const byMsisdn = this.searchAccount && this.searchAccount.startsWith('01')
      const byAccountNumber = this.searchAccount && !this.searchAccount.startsWith('01')

      if (!byMsisdn && !byAccountNumber) return

      const params = byMsisdn ? createGetParams({
        month: month,
        msisdn: this.searchAccount,
      }) : createGetParams({
        month: month,
        accountNumber: this.searchAccount,
      })

      this.loading = true
      this.$rest.get('getFamilyAccountActivities.php', params)
        .then(function (response) {
          this.data = response.data.items
          this.loading = false
        }.bind(this)).catch(error => {
          this.noDataText = error.message
          this.loading = false
        })
    },
    searchByMsisdn: function () {
      const params = createGetParams({
        msisdn: this.searchAccount,
      })
      this.searchSubscriberAccount(params, true)
    },
    searchByAccountNumber: function () {
      const params = createGetParams({
        accountNumber: this.searchAccount,
      })
      this.searchSubscriberAccount(params, false)
    },
    searchSubscriberAccount: function (params, byMsisdn) {
      if (!this.searchAccount || this.searchAccount === this.id) return

      const responseError = byMsisdn ? this.searchAccount + ' not found or not a FamilyPlan number!'
        : 'Account number ' + this.searchAccount + ' not found!'

      this.$rest.get('getFamilyAccount.php', params)
        .then(function (response) {
          this.self = response.data.self
          this.members = response.data.members
          this.dialog = !response.data.members
        }.bind(this)).catch(() => {
          this.responseError = responseError
          this.searchAccount = null
          this.dialog = true
        })

      if (this.dialog) return
      this.getActivities(this.monthOf)
      let path = '/support/family/'

      path += this.searchAccount
      this.$router.push({
        path,
      })
    },

  },
}
</script>
